<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2022-01-24 08:54:29
 * @LastEditors: ------
 * @LastEditTime: 2022-02-18 08:55:18
-->
<template>
  <div class="fangTan_container">
    <van-nav-bar :style="
      type == 1
        ? 'background:linear-gradient(-90deg, #5038FA, #1476FF)'
        : type == 2
          ? 'background:linear-gradient(-90deg, #1ed59c, #22d59d)'
          : type == 3
            ? 'background:linear-gradient(-90deg, #FF9A31, #FFD385);'
            : 'background:linear-gradient(-90deg, #84F5DE, #4CC5F8);'
    " :title="
  type == 1
    ? '客户信息'
    : type == 2
      ? '访谈'
      : type == 3
        ? '合同'
        : '支付'
" left-text="返回" left-arrow placeholder @click-left="$router.push('/customer')" />
    <div class="con">
      <div class="myInfo">
        <div class="info">
          <div class="title">
            <van-image width="0.5rem" height="0.5rem" :src="require('../../images/62.png')"></van-image>
            <span>本人信息</span>
            <div class="box">
              <div class="box_item">
                <div class="contain">用户姓名：{{ info.name }}</div>
                <div class="contain">
                  用户手机号：{{ info.mobile || "暂无填写" }}
                </div>
                <div class="contain">
                  用户身份证：{{ info.idCard || "暂无填写" }}
                </div>
                <!-- <div class="contain contains">
                所在城市：{{ info.city || "暂无填写" }}
              </div> -->
                <div class="contain contains">
                  居住地址：{{ info.detailAddress || "暂无填写" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="talk">
          <van-image width="0.5rem" height="0.5rem" :src="require('../../images/63.png')"></van-image>
          <span class="titlt_talk">{{
              type == 1
                ? "客户信息"
                : type == 2
                  ? "访谈记录"
                  : type == 3
                    ? "合同记录"
                    : "支付记录"
          }}</span>
          <div v-if="type == 1">
            <div v-cloak v-if="clan.length > 0">
              <div v-cloak v-for="(item, index) in clan" :key="index">
                <div class="talk_item">
                  <div class="tip" :style="
                    index == 0
                      ? 'background: #d8d8d8 linear-gradient(90deg, #fdb179 0%, #ff9158 100%)'
                      : index == 1
                        ? 'background: #d8d8d8 linear-gradient(90deg, #aee49e 0%, #82d3ea 100%)'
                        : index == 2
                          ? 'background: #d8d8d8 linear-gradient(90deg, #6eb2fe 0%, #6871f3 100%)'
                          : index == 4
                            ? 'background: #d8d8d8 linear-gradient(90deg, #fdb179 0%, #ff9158 100%)'
                            : index == 5
                              ? 'background: #d8d8d8 linear-gradient(90deg, #aee49e 0%, #82d3ea 100%)'
                              : index == 6
                                ? 'background: #d8d8d8 linear-gradient(90deg, #6eb2fe 0%, #6871f3 100%)'
                                : 'background: #d8d8d8 linear-gradient(90deg, #fdb179 0%, #ff9158 100%)'
                  ">
                    {{
                        item.kinship == 10
                          ? "父亲"
                          : item.kinship == 11
                            ? "母亲"
                            : item.kinship == 12
                              ? "妻子"
                              : item.kinship == 13
                                ? "丈夫"
                                : item.kinship == 19
                                  ? "兄妹"
                                  : item.kinship == 14
                                    ? "儿子"
                                    : item.kinship == 15
                                      ? "女儿"
                                      : item.kinship == 16
                                        ? "孙子"
                                        : item.kinship == 17
                                          ? "孙女"
                                          : item.kinship == 18
                                            ? "朋友"
                                            : item.kinship == 20
                                              ? "其他"
                                              : item.kinship == 99
                                                ? "自己"
                                                : "其他"
                    }}
                  </div>
                  <div class="serve_name">
                    <span>{{ item.name }}</span>
                    <van-icon class="icon" name="arrow" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="type == 2">
            <div v-cloak v-if="talkList.records && talkList.records.length > 0">
              <div v-cloak v-for="(item, index) in talkList.records" :key="index">
                <div class="talk_item" @click="look(item)">
                  <div class="tip">
                    {{
                        (item.appointmentStartTime &&
                          item.appointmentStartTime.substring(
                            0,
                            item.appointmentStartTime.indexOf(" ")
                          )) ||
                        "时间暂无"
                    }}
                  </div>
                  <div class="serve_name">
                    <span>服务对象：{{ item.accountName }}</span>
                    <van-icon class="icon" name="arrow" />
                  </div>
                </div>
              </div>
            </div>
            <div v-cloak class="none_talk" v-else>
              <div class="create_talk">
                <van-button type="primary" :to="{
                  path: '/lookcontainer',
                  query: { obj: JSON.stringify(info) },
                }">点击创建访谈</van-button>
              </div>
            </div>
          </div>
          <div v-if="type == 3">
            <div v-cloak v-if="records.length > 0">
              <div v-cloak v-for="(item, index) in records" :key="index">
                <div class="talk_item" @click="btnClick(item.orderNo, item.storeName)">
                  <div class="tip" style="background: linear-gradient(0deg, #ff9a31, #ffd385)">
                    {{
                        item.createTime.substring(0, item.createTime.indexOf(" "))
                    }}
                  </div>
                  <div class="serve_name">
                    <span>{{ item.accountFamilyName }}_{{
                        item.appointmentStartTime &&
                        item.appointmentStartTime.substring(
                          0,
                          item.appointmentStartTime.indexOf(" ")
                        )
                    }}~{{
    item.appointmentEndTime &&
    item.appointmentEndTime.substring(
      0,
      item.appointmentEndTime.indexOf(" ")
    )
}}</span>
                    <van-icon class="icon" name="arrow" />
                  </div>
                </div>
              </div>
            </div>
            <div v-cloak class="none_talk" v-else>
              <div class="create_talk">
                <van-button color="#ccc">暂无合同记录</van-button>
              </div>
            </div>
            <van-popup v-model="show">
              <div style="width: 100%" class="popCell">
                <van-cell-group inset>
                  <b class="p">合同信息</b>
                  <van-cell title="购买人" :value="clanItem.accountName" />
                  <van-cell title="服务类型" :value="
                    clanItem.nurseType == '12'
                      ? '24小时生活照护'
                      : clanItem.nurseType == '14'
                        ? '钟点服务'
                        : clanItem.nurseType == '13'
                          ? '短期应急'
                          : clanItem.nurseType == '17'
                            ? '适老无障碍设施改造'
                            : '其他'
                  " />
                  <van-cell title="购买数量" :value="clanItem.buyNumber" />
                  <van-cell title="门店" :value="clanItem.storeName || '暂未填写'" />
                  <van-cell title="实付金额（CNY）" :value="clanItem.totalAmount || '0'" />

                  <van-cell title="老人姓名" :value="clanItem.accountFamilyName" />
                  <van-cell title="订购产品" :value="clanItem.orderSubject" />
                  <van-cell title="合同时间" :value="clanItem.createTime" />
                  <van-cell title="护理员" :value="clanItem.assistantName || '暂无'" />
                  <b class="p">老人信息</b>
                  <van-cell title="姓名" :value="clanItem.accountName" />
                  <van-cell title="联系方式" :value="clanItem.accountMobile" />
                  <van-cell title="医保卡号" :value="'暂未填写'" />
                  <van-cell title="服务地址" :value="clanItem.fullAddress" />

                  <b class="p">预约信息</b>
                  <van-cell title="预约开始日期" :value="clanItem.appointmentStartTime" />
                  <b class="p">销售负责人</b>
                  <van-cell title="销售负责人" :value="clanItem.salesman || '暂无'" />
                </van-cell-group>
              </div>
            </van-popup>
          </div>
          <div v-if="type == 4">
            <div v-cloak v-if="records.length > 0">
              <div v-cloak v-for="(item, index) in records" :key="index">
                <div class="talk_item">
                  <div class="tip" style="
                      background: linear-gradient(
                        0deg,
                        #84f5de,
                        #56e8f2,
                        #4cc5f8
                      );
                    ">
                    {{
                        item.finalPayTime.substring(
                          0,
                          item.createTime.indexOf(" ")
                        )
                    }}
                  </div>
                  <div class="serve_name">
                    <div class="pay">
                      <p>支付单号: {{ item.payNo }}</p>
                      <p>服务对象: {{ item.accountName }}</p>
                      <p>支付金额: {{ item.payAmount || "0" }}元</p>
                    </div>
                    <div class="payBtn" v-if="item.payStatus == '10'">
                      <van-button size="small" round color=" linear-gradient(0deg, #6EAEFC, #6875F1)" @click="
                        $router.push({
                          path: '/bulu',
                          query: {
                            id: item.id,
                          },
                        })
                      ">补录</van-button>
                      <van-button size="small" round color="linear-gradient(0deg, #FF9A31, #FFD385)"
                        @click="btnClickPay(item.payNo)">付款码</van-button>
                      <div class="over">
                        <van-overlay :show="show1" @click="vueqr()">
                          <div class="wrapper">
                            <!-- <div class="block" /> :logoSrc="imageUrl"-->
                            <div class="block">
                              <div>微信支付</div>
                              <vue-qr :text="payMessage" :logoSrc="require('../../images/icon_ajh.png')" :size="230">
                              </vue-qr>
                              <div class="fontTip">
                                请使用微信扫一扫<br />扫描二维码支付
                              </div>
                            </div>
                          </div>
                        </van-overlay>
                      </div>
                    </div>
                    <div class="payBtn" v-else>
                      <van-button size="small" round color="#ccc">已支付</van-button>
                    </div>
                    <!-- <van-icon class="icon" name="arrow" /> -->
                  </div>
                </div>
              </div>
            </div>
            <div v-cloak class="none_talk" v-else>
              <div class="create_talk">
                <van-button color="#ccc">暂无支付记录</van-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vueQr from "vue-qr";
import {
  getLookInfo,
  getLookInfoCity,
  getTalkList,
  getLookInfoClan,
  getClanInfoCon,
  getPayList,
  getClanDetail,
  PostPay,
} from "@/api/index.js";
export default {
  name: "",
  components: {
    vueQr,
  },
  data() {
    return {
      info: {},
      talkList: {},
      clan: [],
      records: [],
      type: "",
      show: false,
      clanItem: {},
      show1: false,
      payMessage: null,
    };
  },
  computed: {
    cssVars() {
      return {
        "--color":
          this.type == 1
            ? "linear-gradient(-90deg, #5038FA, #1476FF)"
            : this.type == 2
              ? "linear-gradient(-90deg, #1ed59c, #22d59d)"
              : this.type == 3
                ? " linear-gradient(-90deg, #FF9A31, #FFD385);"
                : "linear-gradient(-90deg, #84F5DE, #4CC5F8);",
      };
    },
  },

  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.type = this.$route.query.type;
    this.getInfo();
  },

  // 生命周期 - 挂载完成（可以访问DOM元素）
  // 方法集合
  methods: {
    vueqr(){
      this.show1 = false
      this.$router.go(0)
    },
    async getInfo() {
      const res = await getLookInfo({ id: this.$route.query.id });
      this.info = res.data.result;
      if (this.type == 1) {
        let res1 = await getLookInfoClan({ id: this.info.accountId });
        this.clan = res1.data.result; // 家庭成员信息
      }
      if (this.type == 2) {
        const getCity = await getLookInfoCity({
          accountId: this.$route.query.id,
        });
        this.info.city = getCity.data.message; //所在城市
        const talk = await getTalkList({ accountId: this.$route.query.id });
        // console.log(talk.data.result);
        this.talkList = talk.data.result;
      }
      if (this.type == 3) {
        let res = await getClanInfoCon({
          accountId: this.$route.query.id,
          pageSize: 999,
        });
        this.records = res.data.result.records;
      }
      if (this.type == 4) {
        let res = await getPayList({
          pageSize: 999,
          accountId: this.$route.query.id,
        });
        this.records = res.data.result.records;
        console.log(this.records);
      }
    },
    look(item) {
      this.$router.push({
        path: "/lookcontainer",
        query: { obj: JSON.stringify(item) },
      });
    },
    async btnClick(item, storeName) {
      let res = await getClanDetail(item);
      console.log(res, "-----------", storeName);
      this.show = true;
      this.clanItem = res.data.result;
      this.clanItem.storeName = storeName;
    },
    btnClickPay(payNo) {
      //       const formData = new FormData();
      // console.log(formData.append("phoneNum", payNo));
      let that = this;
      // console.log(payNo);
      PostPay(payNo).then((res) => {
        // console.log(res, "000000000000000000000000");
        that.payMessage = res.data.message;
        that.show1 = true;
      });
    },
  },
};
</script>
<style lang="less" scoped>
html {
  // height: 100vh;
  background: #f0efef;
}

[v-cloak] {
  display: none !important;
}

.fangTan_container {
  background: #f0efef;
  width: 100%;
  height: 100vh;
}

/deep/ .van-nav-bar {
  background: var(--color);

  .van-nav-bar__title {
    color: #fff;
  }
}

/deep/ .van-nav-bar__left {
  .van-icon,
  .van-nav-bar__text {
    color: #fff;
  }
}

.con {
  background: #f0efef;
}

.myInfo {
  margin: 14px;

  .info {
    height: 200px;

    .title {
      height: 20px;
      line-height: 20px;

      span {
        margin-left: 6px;
        font-size: 16px;
        color: #333333;
      }

      .box {
        width: 347px;
        height: 153px;
        background: #ffffff;
        border-radius: 6px;
        margin-top: 10px;

        .box_item {
          padding: 14px 0 0 26px;
          width: 272px;
          height: 118px;
          font-size: 14px;
          font-family: Alibaba PuHuiTi;
          font-weight: 300;
          color: #666666;
          line-height: 26px;

          .contains {
            word-break: keep-all;
            /* 不换行 */
            width: 272px;
            overflow-x: auto;
            white-space: nowrap;
            /* 不换行 */
          }
        }
      }
    }
  }

  .talk {
    margin-top: 14px;

    .titlt_talk {
      margin-left: 6px;
      font-size: 16px;
      color: #333333;
    }

    .talk_item {
      width: 347px;
      height: 104px;
      margin-top: 14px;
      background: #ffffff;
      border-radius: 14px 6px 6px 6px;

      .tip {
        width: 81px;
        height: 28px;
        text-align: center;
        line-height: 28px;
        color: #fff;
        background: linear-gradient(0deg, #0ecde1 0%, #2bd79f 100%);
        border-radius: 14px 0px 14px 0px;
        font-size: 12px;
      }

      .serve_name {
        text-align: center;
        line-height: 52px;
        font-size: 14px;
        position: relative;

        .pay {
          text-align: left;
          margin-left: 12px;
          line-height: 4px;
          color: #666666;
        }

        .payBtn {
          position: absolute;
          right: 10px;
          top: -6px;

          button {
            width: 58px;
            height: 26px;
            margin-right: 10px;
          }
        }

        .icon {
          margin-left: 20px;
        }
      }
    }

    .none_talk {
      margin-top: 50px;

      .create_talk {
        width: 100%;
        text-align: center;

        button {
          width: 230px;
          background: linear-gradient(0deg, #6eaefc, #6875f1);
          font-size: 15px;
          border-radius: 21px;
          border: none;
        }
      }
    }
  }

  /deep/ .van-popup {
    width: 90%;
    height: 70%;
  }

  .popCell {
    padding: 20px 0;

    .p {
      font-size: 20px;
    }
  }
}

.over {
  /deep/ .van-overlay {
    background: rgba(0, 0, 0, 0.1);
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .block {
    width: 304px;
    height: 386px;
    background-color: #fff;
  }

  .fontTip {
    line-height: 22px;
  }
}
</style>
